import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';
import styled from 'styled-components';

const Breadcrumbs = ({ className, parents = [], self }) => {
  const location = useLocation();

  return (
    <ul className={className}>
      <li>
        <Link to="/">{location.host}</Link>
      </li>
      {parents.filter(Boolean).map((parent) => (
        <li key={parent.id || parent.slug}>
          <Link to={parent.slug}>{parent.title}</Link>
        </li>
      ))}
      <li>
        <span>{self.title}</span>
      </li>
    </ul>
  );
};

export default styled(Breadcrumbs)`
  display: none;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    border-bottom: 1px solid ${({ theme }) => theme.grayColor};
    color: ${({ theme }) => theme.grayColor};
    font-size: .75rem;
    list-style: none;
    padding: 1rem 0;
    margin: 0;
    display: flex;
    font-weight: lighter;
    text-transform: uppercase;
    li {
      &:not(:last-child) {
        &:after {
          content: '>';
          padding: 0.25rem;
        }
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;
