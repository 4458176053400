import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from 'gatsby-theme-core/src/layout/layout';
import Container from 'gatsby-theme-core/src/components/container';
import PageImage from 'gatsby-theme-core/src/components/page-image';
import Content from 'gatsby-theme-core/src/components/content';
import Previews from 'gatsby-theme-vehicles/src/sections/previews';
import Phone from 'icons/src/Phone';
import MapsMarker from 'icons/src/MapsMarker';
import LinkIcon from 'icons/src/Link';
import Envelope from 'icons/src/Envelope';
import Breadcrumbs from '../components/breadcrumbs';
import DealerLogo from '../components/dealer-logo';

export const pageQuery = graphql`
  fragment DealerHead on StrapiDealers {
    headTitle: title
    headSlug: slug
    # headMetaDescription: ...
    headImage: logo {
      url # needed for projection
      src: url
      height
      width
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 400, quality: 80)
        }
      }
    }
  }

  query EaDealerById($id: String!) {
    metadata: site {
      siteMetadata {
        title
      }
    }

    dealer: strapiDealers(id: { eq: $id }) {
      id
      title
      slug
      email
      tel
      street
      houseNumber
      postcode
      city
      website
      logo {
        url
        height
        width
        name
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              height: 32
              placeholder: TRACED_SVG
            )
          }
        }
      }
      pageImage {
        url
        height
        width
        name
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              breakpoints: [576, 768, 992, 1200, 2400]
            )
          }
        }
      }
      pageContent: childStrapiDealersPageContent {
        childMarkdownRemark {
          html
        }
      }

      vehicles {
        ...VehiclePreview
      }

      ...DealerHead
    }
  }
`;

const Dealer = ({ data }) => {
  const { dealer } = data;
  return (
    <>
      {dealer.pageImage && (
        <PageImage darker image={dealer.pageImage}>
          <StyledContainer>
            <Title>{dealer.title}</Title>
          </StyledContainer>
        </PageImage>
      )}
      <Container>
        {!dealer.pageImage && (<Breadcrumbs self={{ title: dealer.title }} />)}
        <Grid>
          <DealerName>{dealer.title}</DealerName>
          {dealer.logo && (
            <div>
              <DealerLogo
                css="margin-bottom: 1rem;"
                image={dealer.logo}
                alt={dealer.title}
              />
            </div>
          )}
          {dealer.tel && (
            <DealerInfoItem>
              <DealerInfoIcon as={Phone} />
              <a href={`tel:${dealer.tel}`}>{dealer.tel}</a>
            </DealerInfoItem>
          )}
          <DealerInfoItem>
            <DealerInfoIcon as={MapsMarker} />
            <address>
              {dealer.street} {dealer.houseNumber}<br />
              {dealer.postcode} {dealer.city}<br />
            </address>
          </DealerInfoItem>
          {dealer.website && (
            <DealerInfoItem>
              <DealerInfoIcon as={LinkIcon} />
              <a href={dealer.website}>Website</a>
            </DealerInfoItem>
          )}
          {dealer.email && (
            <DealerInfoItem>
              <DealerInfoIcon as={Envelope} />
              <a href={`mailto:${dealer.email}`}>{dealer.email}</a>
            </DealerInfoItem>
          )}
        </Grid>

        <Content $content={dealer.pageContent} />

        <Previews css="margin-bottom: 2rem;" vehicles={dealer.vehicles} limit={999} />

      </Container>
    </>
  );
};

const StyledContainer = styled(Container)`
  height: 10rem;
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
`;

const Grid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  padding: 2rem 0;
  grid-template-columns: 1fr;
  grid-gap: 0 ${({ theme }) => theme.grid.columnGap};

  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const DealerName = styled.h3`
  color: ${({ theme }) => theme.h3Color};
  font-size: 1.25rem;
  padding-bottom: 1rem;

  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    padding-bottom: 0;
  }
`;

const DealerInfoIcon = styled.svg`
  position: absolute;
  top: 1rem;
  left: 0;
  width: 1rem;
  color: ${({ theme }) => theme.primaryColor};
`;

const DealerInfoItem = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.grayLightColor};
  padding: 1rem 1rem 1rem 2rem;
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.fontColor};
    text-decoration: none;
    transition: .2s;
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const DealerLayout = (props) => (
  <Layout
    {...props.data.dealer}
    headMetaDescription={`Bekijk de voorraad van ${props.data.dealer.title} op ${props.data.metadata.siteMetadata.title}`}
  >
    <Dealer {...props} />
  </Layout>
);

export default DealerLayout;
